import React from "react"

// import LatencyTest from "../components/latency_test"
import Layout from "../components/layout"
import FloatingWhatsApp from "react-floating-whatsapp"
import "@cloudflare/cloudflare-brand-assets/css/components/number.css"
import "@cloudflare/cloudflare-brand-assets/css/components/superscript.css"
import "@cloudflare/cloudflare-brand-assets/css/components/link.css"
import "@cloudflare/cloudflare-brand-assets/css/components/button.css"
import "@cloudflare/cloudflare-brand-assets/css/components/inline-code.css"
import "@cloudflare/cloudflare-brand-assets/css/components/code-block.css"
import "@cloudflare/cloudflare-brand-assets/css/components/markdown-lite.css"
import "@cloudflare/cloudflare-brand-assets/css/components/unordered-list-workers-themed.css"

import "./section-css/hero-section.css"
import "./section-css/quote-section.css"
import "./section-css/solution-section.css"
import "./section-css/great-power-section.css"
import "./section-css/problems-with-weighbridge.css"
import "./section-css/benefits-section.css"
import "./section-css/customer-logos-section.css"
import "./section-css/plans-section.css"
import "./section-css/contact-section.css"
import "./section-css/weigh-bridge-boxes.css"

const IndexPage = () => {
  return (
    <Layout>
      <section className="HeroSection" id="#home">
        <div className="HeroSection--column">
          <div className="HeroSection--illustration">
            <div>
              <img
                src="./resources/illustrations/myweighbridge.gif"
                alt="gif"
                className="img-gif"
              />
            </div>
          </div>
          <div className="HeroSection--content">
            <h1 className="HeroSection--title">
              <div className="HeroSection--title-first-line">
                My Weighbridge App.
              </div>
            </h1>
            <div className="HeroSection--description">
              <p>Bring change to the weighing world with technology.</p>
            </div>
            <div className="HeroSection--actions HeroSection--actions-desktop">
              <div className="HeroSection--actions-item">
                <a className="Button Button-is-primary" href="#contact">
                  BOOK A FREE DEMO
                </a>
              </div>
            </div>
            <div className="HeroSection--actions HeroSection--actions-mobile">
              <div className="HeroSection--actions-item">
                <a className="Button Button-is-primary" href="#contact">
                  BOOK A FREE DEMO
                </a>
              </div>
            </div>
            <div className="HeroSection--key-points">
              <ul className="UnorderedListWorkersThemed UnorderedListWorkersThemed-is-primary">
                <li>Fast Supply Chain management</li>
                <li>Enhanced Efficiency and Productivity</li>
                <li>Manages Multi-Location Architecture</li>
                <li>Comprehensive reporting and full integration</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="QuoteSection">
        <div className="QuoteSection--column">
          <cite
            className="QuoteSection--cite"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="QuoteSection--cite-name">
              What Is Myweighbridge App?
            </div>
            <div className="QuoteSection--cite-title">
              Automate Data Control On Weighing Processes
            </div>
            <figure
              className="QuoteSection--cite-company-logo"
              aria-label="NPM logo"
            >
              <img
                src="./resources/illustrations/weighbridge_illustration1-1.svg"
                alt="img"
                width="100%"
                height="100%"
              />
            </figure>
          </cite>

          <blockquote className="QuoteSection--quote">
            <p>
              "MyWeighbridge App” allows weight data entry management by
              connecting all Remotely located weighbridges at one place on your
              mobile. The App is a streamlined, paperless data management
              service suitable for all industries and all public weighbridges
              operations. It is integrated with the primary weight management
              system, which includes logistics, and orders.
            </p>
            <br />
            <p>
              &nbsp;&nbsp;It offers daily operator’s work with intuitive weigh
              data entry management by differentiation of material
              reception/sending with the electronic monitoring of weighing
              equipment by avoiding the use of heavy and limiting manual
              operating panels.
            </p>
            <br />
            <p>
              &nbsp;&nbsp;All transactions are stored as a database, enabling
              the administrator to edit daily, monthly, or yearly detailed
              reports. It also has an access right management function for each
              user who can customize screens according to their preferences.
            </p>
          </blockquote>
        </div>
      </section>

      <section className="ProblemsWithWeighSection">
        <h2 className="ProblemsWithWeighSection--title">
          <div className="ProblemsWithWeighSection--title-first-line">
            Problems With Current Weighing Systems
          </div>
          <div className="ProblemsWithWeighSection--title-second-line">
            Choose Possibility In Every Direction
          </div>
        </h2>
        <p className="ProblemsWithWeighSection--descrption">
          Trucks and heavy vehicles are a medium of transporting goods from one
          location to another in Industries such as Mining, Metal, Quarries,
          Iron and Steel, etc. Ensuring that the right material is delivered and
          there is no manipulation of data entries becomes a matter of concern
          for such industries as it can cause huge monetary losses if not taken
          proper care of.
        </p>
      </section>
      <section
        className="WeighBridgBoxesSection WeighBridgBoxesSection-is-after-customer-logos-section"
        id="WeighBridgBoxes"
      >
        <div className="WeighBridgBoxesSection--column">
          <div className="WeighBridgBoxesSection--plans">
            <div className="WeighBridgBoxesSection--plan PlansSection--plan-is-free">
              <div className="WeighBridgBoxesSection--plan-header">
                <h2 className="WeighBridgBoxesSection--plan-title">
                  Time-consuming
                </h2>
                <div
                  className="WeighBridgBoxesSection--plan-subtitle"
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Short cold starts illustration"
                    src="./resources/illustrations/hourglass.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
              <div className="WeighBridgBoxesSection--plan-details">
                Manual data point maintenance takes time and effort and prevents
                real-time data visibility.
              </div>
            </div>
            <div className="WeighBridgBoxesSection--plan PlansSection--plan-is-free">
              <div className="WeighBridgBoxesSection--plan-header">
                <h2 className="WeighBridgBoxesSection--plan-title">
                  Human Error
                </h2>
                <div
                  className="WeighBridgBoxesSection--plan-subtitle"
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Short cold starts illustration"
                    src="./resources/illustrations/human-error.png"
                    width="71px"
                    height="71px"
                  />
                </div>
              </div>
              <div className="WeighBridgBoxesSection--plan-details">
                High risk of typos in vehicle numbers, weight information,
                product specifications, etc.
              </div>
            </div>
            <div className="WeighBridgBoxesSection--plan PlansSection--plan-is-free">
              <div className="WeighBridgBoxesSection--plan-header">
                <h2 className="WeighBridgBoxesSection--plan-title">
                  Product Loss
                </h2>
                <div
                  className="WeighBridgBoxesSection--plan-subtitle"
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Short cold starts illustration"
                    src="./resources/illustrations/loss.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
              <div className="WeighBridgBoxesSection--plan-details">
                Weight data recorded incorrectly might leave space for material
                loss and fraud.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="SolutionSection">
        <div className="SolutionSection--column">
          <blockquote className="SolutionSection--solution">
            <p>
              &nbsp;&nbsp;Myweighbridge App is an easy and adaptable solution to
              handle and monitors the important data generated throughout the
              process of weighing and labeling products. In order to fetch
              accurate traffic reports in the future, our app and system present
              load displays of weighments along with their products and truck
              driver contact information.
            </p>
            <br />
            <p>
              &nbsp;&nbsp;It simply integrates with the electronic terminal to
              get the data about the hierarchies using several APIs and user
              actions. If you work in the equipment industry, you must set
              unmanned systems for automated function so that you can reach
              users on an app with just one click and handle myriad global
              settings from centralized systems. We offer both customized
              systems and Pre-built systems that are specially made.
            </p>
          </blockquote>
          <cite
            className="SolutionSection--cite"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="SolutionSection--cite-name">
              Solution Delivered By Myweighbridge
            </div>
            <div className="SolutionSection--cite-title">
              Collect All Remote Weighbridge Ticket Data At Single Place
            </div>
            <figure
              className="SolutionSection--cite-company-logo"
              aria-label="NPM logo"
            >
              <img
                src="./resources/illustrations/dashboard-image.png"
                alt="img"
                width="100%"
                height="100%"
              />
            </figure>
          </cite>
        </div>
      </section>

      <section className="GreatPowerSection">
        <h2 className="GreatPowerSection--title">
          <div className="GreatPowerSection--title-second-line">
            Myweighbridge App provides
          </div>
        </h2>
      </section>

      <section className="BenefitsSection">
        <div className="BenefitsSection--column">
          <div className="BenefitsSection--benefits">
            <div className="BenefitsSection--benefit">
              <div className="BenefitsSection--benefit-illustration">
                <img
                  alt="Scaling illustration"
                  src="./resources/illustrations/location.png"
                  width="70px"
                  height="70px"
                />
              </div>
              <h3 className="BenefitsSection--benefit-title">
                Multiple Location Management
              </h3>
              <div className="BenefitsSection--benefit-description">
                The weighbridge app provides the facility to connect
                all remotely located weighbridges at one place on your mobile.
              </div>
            </div>
            <div className="BenefitsSection--benefit">
              <div
                className="BenefitsSection--benefit-illustration"
                light-theme-only="true"
              >
                <img
                  alt="Global network illustration"
                  src="./resources/illustrations/notification.png"
                  width="70px"
                  height="70px"
                />
              </div>
              <h3 className="BenefitsSection--benefit-title">
                Weight Data Entry Alert
              </h3>
              <div className="BenefitsSection--benefit-description">
                <div className="MarkdownLite">
                  Get the Live weight entry data via telegram, SMS, and Email
                  notification so you can't skip any valuable details about the
                  business.
                </div>
              </div>
            </div>
            <div className="BenefitsSection--benefit">
              <div
                className="BenefitsSection--benefit-illustration"
                light-theme-only="true"
              >
                <img
                  alt="Templates illustration"
                  src="./resources/illustrations/analytics.png"
                  width="70px"
                  height="70px"
                />
              </div>
              <h3 className="BenefitsSection--benefit-title">
                Real-Time Analytical Report
              </h3>
              <div className="BenefitsSection--benefit-description">
                App enables real-time reports on the weight of goods, with necessary details on a weekly, monthly, and yearly basis custom filter Facility.
              </div>
            </div>
            <div className="BenefitsSection--benefit">
              <div
                className="BenefitsSection--benefit-illustration"
                light-theme-only="true"
              >
                <img
                  alt="Short cold starts illustration"
                  src="./resources/illustrations/magnifying-glass.png"
                  width="70px"
                  height="70px"
                />
              </div>
              <h3 className="BenefitsSection--benefit-title">
                Easy-to-use Interface
              </h3>
              <div className="BenefitsSection--benefit-description">
                <div className="MarkdownLite">
                  <p>
                    No expert needed to use Myweighbridge App, It's
                    User-friendly that can be operated by anybody.
                  </p>
                </div>
              </div>
            </div>
            <div className="BenefitsSection--benefit">
              <div
                className="BenefitsSection--benefit-illustration"
                light-theme-only="true"
              >
                <img
                  alt="Short cold starts illustration"
                  src="./resources/illustrations/secure-data.png"
                  width="70px"
                  height="70px"
                />
              </div>
              <h3 className="BenefitsSection--benefit-title">
                Securely stored on Cloud
              </h3>
              <div className="BenefitsSection--benefit-description">
                <div className="MarkdownLite">
                  <p>
                    Optimize all of your weighbridge data across all of your
                    sites and locations onto a single Cloud server.
                  </p>
                </div>
              </div>
            </div>
            <div className="BenefitsSection--benefit">
              <div
                className="BenefitsSection--benefit-illustration"
                light-theme-only="true"
              >
                <img
                  alt="Short cold starts illustration"
                  src="./resources/illustrations/dashboard.png"
                  width="70px"
                  height="70px"
                />
              </div>
              <h3 className="BenefitsSection--benefit-title">
                Visualize Dashboard
              </h3>
              <div className="BenefitsSection--benefit-description">
                <div className="MarkdownLite">
                  <p>
                    A Dashboard to see all the processes in one place.
                    Presenting important data in a way that is easy to digest
                    with useful filters.
                  </p>
                </div>
              </div>
            </div>
            <div className="BenefitsSection--benefit">
              <div
                className="BenefitsSection--benefit-illustration"
                light-theme-only="true"
              >
                <img
                  alt="Short cold starts illustration"
                  src="./resources/illustrations/shield.png"
                  width="70px"
                  height="70px"
                />
              </div>
              <h3 className="BenefitsSection--benefit-title">
                Enhanced Data Security
              </h3>
              <div className="BenefitsSection--benefit-description">
                <div className="MarkdownLite">
                  <p>
                    100% end-to-end secure data reporting provided by our app.
                  </p>
                </div>
              </div>
            </div>

            <div className="BenefitsSection--benefit">
              <div className="BenefitsSection--benefit-illustration">
                <img
                  alt="Serverless illustration"
                  src="./resources/illustrations/send.png"
                  width="70px"
                  height="70px"
                />
              </div>
              <h3 className="BenefitsSection--benefit-title">Flexibility</h3>
              <div className="BenefitsSection--benefit-description">
                A software that can easily be upgraded at any moment and across
                several platforms.
              </div>
            </div>
          </div>
          <div className="BenefitsSection--cta">
            <h2 className="BenefitsSection--cta-title">
              Does Your Business Have Multiple Weighbridge Manage?
            </h2>

            <div className="BenefitsSection--cta-cta">
              <a className="Button Button-is-secondary-orange" href="#contact">
                SUBSCRIBE OUR PLAN
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="PlansSection PlansSection-is-after-customer-logos-section"
        id="plans"
      >
        <div className="PlansSection--column">
          <div className="PlansSection--plans">
            <div className="PlansSection--plan PlansSection--plan-is-free">
              <div className="PlansSection--plan-header">
                <h2 className="PlansSection--plan-title">Admin Panel</h2>
                <div
                  className="PlansSection--plan-subtitle"
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Short cold starts illustration"
                    src="./resources/illustrations/admin-panel.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>

              <div className="PlansSection--plan-cta">
                <a
                  className="Button Button-is-secondary-orange"
                  light-theme-only="true"
                  href="https://app.myweighbridge.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  GET STARTED FROM HERE
                </a>
              </div>
            </div>
            <div className="PlansSection--plan PlansSection--plan-is-free">
              <div className="PlansSection--plan-header">
                <h2 className="PlansSection--plan-title">Android App</h2>
                <div
                  className="PlansSection--plan-subtitle"
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Short cold starts illustration"
                    src="./resources/illustrations/android.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
              <div className="PlansSection--plan-cta">
                <a
                  className="Button Button-is-secondary-orange"
                  light-theme-only="true"
                  href="https://play.google.com/store/apps/details?id=com.myweighbridge.android"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  DOWNLOAD FROM HERE
                </a>
              </div>
            </div>
            <div className="PlansSection--plan PlansSection--plan-is-free">
              <div className="PlansSection--plan-header">
                <h2 className="PlansSection--plan-title">iPhone App</h2>
                <div
                  className="PlansSection--plan-subtitle"
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Short cold starts illustration"
                    src="./resources/illustrations/app-store.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
              <div className="PlansSection--plan-cta">
                <a
                  className="Button Button-is-secondary-orange"
                  light-theme-only="true"
                  target={"_blank"}
                  href="https://apps.apple.com/app/myweigh-bridge/id6450603048"
                >
                  DOWNLOAD FROM HERE
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="QuoteSection" id="about">
        <div className="QuoteSection--column">
          <cite
            className="QuoteSection--cite"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="QuoteSection--cite-name">Who We Are?</div>
            <div className="QuoteSection--cite-title">
              Advancing Beyond Your Expectations
            </div>
            <figure
              className="QuoteSection--cite-company-logo"
              aria-label="NPM logo"
            >
              <img
                src="./resources/illustrations/Mockup.svg"
                alt="img"
                width="100%"
                height="100%"
              />
            </figure>
          </cite>

          <blockquote className="QuoteSection--quote">
            <p>
              “Myweighbridge App” is proudly developed by the team Odan Infotech
              LLP. The purpose of the app is to streamline the weight data entry
              management by connecting all Remotely located weighbridges at one
              place on your mobile.
            </p>
            <br />
            <p>
              &nbsp;&nbsp;We offer a complete product development solution from
              business consulting, business app development, strategy building,
              management audits, project management, re-engineering, analytics,
              digital transformation, and automation to the entrepreneurs.
            </p>
            <br />
            <p>
              &nbsp;&nbsp;We commit ourselves to achieve consistent reliability,
              efficiency, and performance of our products & services related to
              IT Software, and weighing solution. We attempt to achieve maximum
              customer satisfaction through continuous feedback mechanisms and
              continual improvement in Quality Management Systems.
            </p>
          </blockquote>
        </div>
      </section>
      <section
        className="ContactSection ContactSection-is-after-customer-logos-section"
        id="contact"
      >
        <div className="ContactSection--column">
          <h2 className="ContactSection--cta-title">Contact details</h2>
          <div className="ContactSection--plans">
            <div className="ContactSection--plan PlansSection--plan-is-free">
              <div className="ContactSection--plan-header">
                <h2 className="ContactSection--plan-title">Our Location</h2>
                <div
                  className="ContactSection--plan-subtitle"
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Short cold starts illustration"
                    src="./resources/illustrations/location-white.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
              <div className="ContactSection--plan-details">
                901-902, Orbit-2, Canal Road, Bharthana, Vesu, Surat-395007
              </div>
            </div>
            <div className="ContactSection--plan PlansSection--plan-is-free">
              <div className="ContactSection--plan-header">
                <h2 className="ContactSection--plan-title">Get In Touch</h2>
                <div
                  className="ContactSection--plan-subtitle"
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Short cold starts illustration"
                    src="./resources/illustrations/email.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
              <div className="ContactSection--plan-details">
                Contact@myweighbridge.com
              </div>
            </div>
            <div className="ContactSection--plan PlansSection--plan-is-free">
              <div className="ContactSection--plan-header">
                <h2 className="ContactSection--plan-title">Working Hours</h2>
                <div
                  className="ContactSection--plan-subtitle"
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Short cold starts illustration"
                    src="./resources/illustrations/time.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
              <div className="ContactSection--plan-details">
                Mon-Fri: 8:00 - 16:00 Sat-Sun: Closed
              </div>
            </div>
          </div>
        </div>
      </section>
      <FloatingWhatsApp
        phoneNumber="+919723238432"
        accountName="My WeighBridge"
        avatar="/resources/logo/logo_white.png"
      />
    </Layout>
  )
}

export default IndexPage
